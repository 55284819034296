<template>
  <BaseModalContent
    :dynamic="true"
    @close="$emit('close')">
    <BaseLoadingBox :pending="formPending">
      <h2 class="text-bold text-size-h2 mb-m">Образование</h2>
      <div
        v-if="educationOptions.length"
        class="form-row">
        <div class="mb-s">
          Пожалуйста, укажите в&nbsp;каком образовательном учреждении
          вы&nbsp;обучаетесь на&nbsp;данный момент
        </div>
        <BaseSelect
          v-model="currentEducation"
          placeholder="Школа"
          :options="educationOptions"
          :allow-empty="false"
          :preselect-first="true"
          track-by="id"
          label="title"
          :max-height="250"
          :searchable="false" />
      </div>

      <!-- Форма создания нового образования -->
      <div
        v-if="isNewEducation"
        class="edu-form">
        <ValidationObserver
          ref="form"
          tag="form"
          @submit.prevent="handleSubmit">
          <div class="mb-m">
            <div
              v-if="isStudent"
              class="mb-m">
              <BaseSelect
                v-model="selectedCategory"
                placeholder="Тип образования"
                :options="educationCategoryOptions"
                :allow-empty="false"
                :preselect-first="true"
                :max-height="250"
                label="name"
                :searchable="false" />
            </div>

            <label
              for=""
              class="form-label text-bold"
              >{{
                isStudent
                  ? "Название образовательного учреждения"
                  : "Название школы"
              }}*</label
            >
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: true }">
              <EducationAutocomplete
                v-model="newEducationData"
                max-height="250px"
                :errors="errors"></EducationAutocomplete>
            </ValidationProvider>

            <div class="form-row mt-s text-size-s">
              <p v-if="isStudent">
                Начните вводить название своего образовательного учреждения,
                например: &laquo;МГУ&raquo;. Если среди предложенных нет нужного
                варианта, укажите название в&nbsp;свободной форме
              </p>
              <p v-else>
                Начните вводить название своей школы, например: &laquo;Школа
                20&nbsp;Тобольск&raquo;. Если вашей школы нет
                в&nbsp;предложенных вариантах, то&nbsp;укажите ее&nbsp;название
                в&nbsp;свободной форме.
              </p>
            </div>
          </div>
          <!-- Название -->

          <div
            v-if="!newEducation.dadata_hid"
            class="mb-m">
            <div class="form-row">
              <label class="form-label text-bold"
                >Город образовательного учреждения*</label
              >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required">
                <AddressAutocomplete
                  v-model="newEducation.address"
                  :errors="errors"
                  :search-options="{
                    from_bound: { value: 'city' },
                    locations: [{ country: '*' }],
                    to_bound: { value: 'settlement' },
                  }" />
              </ValidationProvider>
            </div>
          </div>

          <div v-if="isStudent">
            <div class="row">
              <template v-if="isHighEducation">
                <div class="col-md-6 col-sm-6 col-xs-12 mb-s">
                  <label class="form-label text-bold">Факультет</label>
                  <BaseInput v-model="newEducation.extra.faculty" />
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 mb-s">
                  <label class="form-label text-bold"
                    >Уровень образования</label
                  >
                  <BaseSelect
                    v-model="newEducation.extra.academic_degree"
                    placeholder="Уровень образования"
                    :options="educationLevelOptions"
                    :allow-empty="true"
                    :preselect-first="false"
                    :max-height="250"
                    label="name"
                    :searchable="false" />
                </div>
              </template>
              <div class="col-md-12 col-sm-12 col-xs-12 mb-m">
                <label class="form-label text-bold">Специальность</label>
                <BaseInput v-model="newEducation.extra.speciality" />
              </div>
            </div>
          </div>

          <div>
            <div class="row">
              <div class="col-md-5 col-sm-6 col-xs-12 mb-s">
                <label class="form-label text-bold">Год начала обучения*</label>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required">
                  <BaseSelect
                    v-model="newEducation.start"
                    placeholder="Год"
                    :options="yearsOptions"
                    :allow-empty="false"
                    :preselect-first="true"
                    :max-height="250"
                    :searchable="false" />
                  <div
                    v-if="errors"
                    class="form-error">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div
            v-if="formError"
            class="mt-m form-error">
            {{ formError }}
          </div>
          <BaseButton
            type="submit"
            class="mt-m"
            >Сохранить</BaseButton
          >
        </ValidationObserver>
      </div>
      <div v-else>
        <!-- год начала обучения, если нет -->
        <div v-if="!currentEducation.start">
          <div class="row">
            <div class="col-md-5 col-sm-6 col-xs-12 mb-s">
              <label class="form-label text-bold">Год начала обучения*</label>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required">
                <BaseSelect
                  v-model="newEducation.start"
                  placeholder="Год"
                  :options="yearsOptions"
                  :allow-empty="false"
                  :preselect-first="true"
                  :max-height="250"
                  :searchable="false" />
                <div
                  v-if="errors"
                  class="form-error">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div
          v-if="!currentEducation.address"
          class="mb-m">
          <label class="form-label text-bold"
            >Город образовательного учреждения*</label
          >
          <ValidationProvider
            ref="upAddress"
            v-slot="{ errors }"
            rules="required">
            <AddressAutocomplete
              v-model="updatedAddress"
              :errors="errors"
              :search-options="{
                from_bound: { value: 'city' },
                locations: [{ country: '*' }],
                to_bound: { value: 'settlement' },
              }" />
          </ValidationProvider>
        </div>
        <BaseButton
          class="mt-m"
          @click.prevent="hadleSelectEducation"
          >Сохранить</BaseButton
        >
      </div>
    </BaseLoadingBox>
  </BaseModalContent>
</template>

<script>
import { mapGetters } from "vuex";
import EducationAutocomplete from "@/components/autocomplete/EducationAutocomplete";
import AddressAutocomplete from "@/components/autocomplete/AddressAutocomplete";
import {
  GRADE_BACHELOR,
  GRADE_MASTER,
  GRADE_SPECIALIST,
  HIGH_EDUCATION_ALIAS,
  SECONDARY_EDUCATION_ALIAS,
  SECONDARY_PROF_EDUCATION_ALIAS,
} from "@/constants";
import { talentRequest, request } from "@/services/api";

export default {
  name: "UpdateEducationModal",
  components: {
    EducationAutocomplete,
    AddressAutocomplete,
  },
  data() {
    return {
      formPending: false,
      currentEducation: null,
      formError: "",
      updatedAddress: "",
      selectedCategory: null,
      newEducation: {
        title: "",
        dadata_hid: "",
        address: "",
        start: "",
        extra: {
          speciality: "",
          faculty: "",
          academic_degree: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isStudent: "participant/isStudent",
      user: "user/user",
    }),
    grade() {
      return this.$store.state.participant.grade || this.user.grade;
    },
    userEducations() {
      const { isStudent } = this;
      /**
       * Список образовательных учреждений пользователя,
       * соответствующих его треку.
       */
      const list = this.$store.state.user.educations;
      if (!list?.length) return [];
      return list.filter((education) => {
        if (isStudent) {
          return [
            HIGH_EDUCATION_ALIAS,
            SECONDARY_PROF_EDUCATION_ALIAS,
          ].includes(education.category);
        } else {
          // школьный трек
          return education.category === SECONDARY_EDUCATION_ALIAS;
        }
      });
    },
    newEducationData: {
      get() {
        return this.newEducation.title;
      },
      set(value = {}) {
        this.newEducation.title = value?.title;
        this.newEducation.dadata_hid = value?.dadata_hid;
      },
    },
    educationOptions() {
      const { isStudent } = this;
      const category = isStudent
        ? HIGH_EDUCATION_ALIAS
        : SECONDARY_EDUCATION_ALIAS;
      // текущее должно быть сверху
      const list = this.userEducations
        .filter((n) => n.category === category)
        .sort((a, b) => b.current - a.current);

      return [
        ...list,
        {
          id: "new",
          title: "+ Добавить новое образование",
        },
      ];
    },
    yearsOptions() {
      const start = new Date().getFullYear();
      let result = [];
      for (let i = 12; i >= 0; i--) {
        result.unshift(start - i);
      }
      return result;
    },
    isNewEducation() {
      return !this.currentEducation || this.currentEducation.id === "new";
    },
    educationCategoryOptions() {
      return [
        {
          name: "Среднее-специальное",
          value: SECONDARY_PROF_EDUCATION_ALIAS,
        },
        {
          name: "Высшее",
          value: HIGH_EDUCATION_ALIAS,
        },
      ];
    },
    educationLevelOptions() {
      return [
        {
          name: "Бакалавр",
          value: GRADE_BACHELOR,
        },
        {
          name: "Магистр",
          value: GRADE_MASTER,
        },
        {
          name: "Специалист",
          value: GRADE_SPECIALIST,
        },
      ];
    },
    isProfEducation() {
      return (
        this.isStudent &&
        this.selectedCategory?.value === SECONDARY_PROF_EDUCATION_ALIAS
      );
    },
    isHighEducation() {
      return (
        this.isStudent && this.selectedCategory?.value === HIGH_EDUCATION_ALIAS
      );
    },
  },
  created() {
    if (this.grade) {
      this.newEducation.start = new Date().getFullYear() - this.grade;
    }
  },
  methods: {
    /**
     * Добавляет новое образование в профиль юзера
     * и патчит участника и пользователя таланта
     */
    async handleSubmit() {
      const { isStudent, isNewEducation, isProfEducation, isHighEducation } =
        this;
      if (this.formPending || !isNewEducation) return;
      this.formPending = true;
      this.formError = "";
      let educationId = "";
      // Если это новое образование, то сохраним его
      // в профиль таланта

      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        this.formPending = false;
        return;
      }
      const payload = {
        ...this.newEducation,
        category: isStudent
          ? this.selectedCategory?.value
          : SECONDARY_EDUCATION_ALIAS,
      };

      /**
       * Преобразуем данные в extra
       * для каждого типа обучения
       */
      if (isProfEducation) {
        payload.extra = {
          speciality: this.newEducation.extra.speciality,
        };
      } else if (isHighEducation) {
        payload.extra = {
          ...payload.extra,
          academic_degree: this.newEducation.extra.academic_degree?.value,
        };
      } else {
        payload.extra = {};
      }

      // если образование из дадаты то адрес не нужен
      // если нет, тот не нужен хид
      if (payload.dadata_hid) {
        delete payload.address;
      } else {
        delete payload.dadata_hid;
      }
      try {
        const { data: edu } = await talentRequest({
          method: "POST",
          url: `/api/users/${this.user.talent_id}/educations/`,
          data: payload,
        });
        educationId = edu.id;
        // Обновим стор, (добавим новое образование, скинем статусы current)
        const prevState = [...this.userEducations];
        this.$store.commit("user/SET_STATE", {
          key: "educations",
          value: [...prevState, edu],
        });
        window.dataLayer?.push({
          event: "ntoProfileEvent",
          eventCategory: "nto_profile",
          eventAction: "set_new_education",
        });
      } catch (error) {
        this.formError = error.message;
        this.formPending = false;
        return;
      }
      await this.updateTalentUser(educationId);
      try {
        await this.patchParticipant(educationId);
      } catch (error) {
        this.showErrorModal({
          content:
            "Не удалось обновить данные об образовании для текущего участника.",
          message: error?.message,
        });
      }
      this.formPending = false;
      this.$emit("close");
    },
    async patchParticipant(education_id) {
      await request({
        method: "PATCH",
        url: "/participant",
        data: {
          talent_education_id: education_id,
        },
      });
      this.$store.commit("participant/SET_STATE", {
        key: "talent_education_id",
        value: education_id,
      });
    },
    async updateTalentUser(education_id) {
      try {
        await this.$store.dispatch("user/updateUserInfo", {
          current_education: education_id,
        });
      } catch (error) {
        this.$notify({
          title: "Не удалось обновить данные на платформе Талант",
          text: error.message,
          type: "error",
          group: "base",
        });
      }
    },
    /**
     * Выбирает текущее образование
     */
    async hadleSelectEducation() {
      const education_id = this.currentEducation?.id;
      if (this.formPending || !education_id) return;
      const hasNoAddress = !this.currentEducation?.address;
      if (hasNoAddress) {
        const { valid } = await this.$refs.upAddress.validate();
        if (!valid) return;
      }
      this.formPending = true;
      try {
        const payload = {
          start: this.currentEducation?.start || this.newEducation.start,
        };
        if (hasNoAddress) {
          payload.address = this.updatedAddress;
        }
        // обновляем образование
        const { data } = await talentRequest({
          method: "PATCH",
          url: `/api/users/${this.user.talent_id}/educations/${education_id}/`,
          data: payload,
        });
        // патчим текущего участника новым образованием
        await this.updateTalentUser(this.currentEducation?.id);
        await this.patchParticipant(this.currentEducation?.id);
        this.$store.commit("user/PATCH_EDUCATION", data);
      } catch (error) {
        this.$emit("close");
        this.showErrorModal({
          content:
            "Не удалось обновить данные об образовании для текущего участника.",
          message: error?.message,
        });
        return;
      }
      // скидываем старые current образования в сторе
      const updatedEducations = this.userEducations.map((n) => {
        return {
          ...n,
          current: n.id === education_id,
        };
      });
      this.$store.commit("user/SET_STATE", {
        key: "educations",
        value: updatedEducations,
      });
      this.formPending = false;
      this.$emit("close");
      window.dataLayer?.push({
        event: "ntoProfileEvent",
        eventCategory: "nto_profile",
        eventAction: "set_current_education",
      });
    },
  },
};
</script>

<style></style>
